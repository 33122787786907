/**
 * Seamless switching between two card images (FNG).
 */
@keyframes picFade {
	0% { opacity: 1; }
	40% { opacity: 1; }
	50% { opacity: 0; }
	90% { opacity: 0; }
	100% { opacity: 1; }
}





/**
 * Loader spin.
 */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}





/**
 *
 */
@keyframes blink {
	0% {
		opacity: 1.0;
	}

	50% {
		opacity: 0.0;
	}

	100% {
		opacity: 1.0;
	}
}





/**
 * Shine effect on card image (FNJ).
 */
@keyframes picShine {
	5% {
		top: -70%;
		left: -70%;

		transition-property: left, top, opacity;
		transition-duration: 1s, 1s, 1s;
		transition-timing-function: ease;

		opacity: 1;
	}
	20% {
		top: -70%;
		left: -70%;

		transition-property: opacity;

		opacity: 0;
	}
}





/**
 *
 */
@keyframes bounceIn {
	from, 20%, 40%, 60%, 80%, 100% {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(.97, .97, .97);
	}

	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
		backface-visibility: hidden;
	}
}





/**
 *
 */
@keyframes tada {
	0% {
		transform: scale3d(1, 1, 1);
	}

	10%, 20% {
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
	}

	30%, 50%, 70%, 90% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%, 60%, 80% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}




/**
 *
 */
@keyframes shake {
	from, 100% {
		transform: translate3d(0, 0, 0);
	}

	15%, 45%, 75% {
		transform: translate3d(-5px, 0, 0);
	}

	30%, 60%, 90% {
		transform: translate3d(5px, 0, 0);
	}
}





/**
 *
 */
@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}





/**
 *
 */
@keyframes fadeInOut {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 1;
	}

	75% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}





/**
 *
 */
@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}





/**
 *
 */
@keyframes fadeOutDown {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
}





/**
 *
 */
@keyframes slideInLeft {
	0% {
		transform: translate3d(-100%, 0, 0);

		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}





/**
 *
 */
@keyframes slideInRight {
	0% {
		transform: translate3d(100%, 0, 0);

		visibility: visible;
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}





/**
 * Attack animations.
 */
@include attack-animation('atk-a', '0');
@include attack-animation('atk-a', '1');






/**
 * Defeated animations.
 */
@keyframes defeat-b {
	0% {
		filter: grayscale(0);
		opacity: 1;
	}

	35% {
		filter: grayscale(1);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		opacity: 0;
		filter: grayscale(1);
		transform: translate3d(0, 200px, 0);
	}
}





/**
 *
 */
@keyframes shake {
	from, 100% {
		transform: translate3d(0, 0, 0);
	}

	15%, 45%, 75% {
		transform: translate3d(-5px, 0, 0);
	}

	30%, 60%, 90% {
		transform: translate3d(5px, 0, 0);
	}
}